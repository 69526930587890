<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          Vuexy
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Welcome to CPanel! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Username"
                label-for="login-username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="username"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="pepe"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{name:'auth-forgot-password-v2'}">
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Sign in
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{name:'page-auth-register-v2'}">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text>

          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div>

          <!-- social buttons -->
          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      username: '',
      userId: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    // alert(navigator.platform)
    window.localStorage.removeItem('userId')
    window.localStorage.removeItem('username')
    window.localStorage.removeItem('userData')
    window.localStorage.removeItem('role')
    window.localStorage.removeItem('api_token')
    window.localStorage.removeItem('user')
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          fetch(config.VUE_CONFIG_GAME_LOGIC+'/user/login', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json',
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify({ username: this.username, password: this.password }), // body data type must match "Content-Type" header
          }).then(r => {
            // console.log(r)
            r.json().then(r2 => {
              if (r2.api_token !== undefined) {
                window.localStorage.setItem('username', this.username)
                window.localStorage.setItem('userId', this.username)

                window.localStorage.setItem('api_token', r2.api_token)
                window.localStorage.setItem('role', r2.user.role)
                let base64 = window.btoa(unescape(encodeURIComponent( JSON.stringify(r2.user)  )));

                window.localStorage.setItem('user', base64)

                const abilitiesArray = 
                [
                  {
                      title: 'admin',
                      ability: [
                      {
                          action: 'manage',
                          subject: 'all'
                      }
                    ]
                  },
                  {
                    title: 'moderator',
                    ability:
                    [ 
                      {
                        action: 'read',
                        subject: 'moderable',
                      },
                      {
                        action: 'read',
                        subject: 'Auth',
                      }
                    ]
                  },
                  {
                    title: 'master',
                    ability:
                    [ 
                      {
                        action: 'read',
                        subject: 'master',
                      },
                      {
                        action: 'read',
                        subject: 'Auth',
                      }
                    ]
                  },
                  {
                    title: 'chat-attendant',
                    ability:
                    [ 
                      {
                        action: 'read',
                        subject: 'moderable',
                      },
                      {
                        action: 'read',
                        subject: 'chat-attendant',
                      },
                      {
                        action: 'read',
                        subject: 'Auth',
                      }
                    ]
                  }
                ]

                let currentAbility = abilitiesArray.find(abilitiesArray => abilitiesArray.title === r2.user.role);
                let uData = {
                              "id": r2.user.id,
                              "fullName": r2.user.name,
                              "username": this.username,
                              "avatar": "/img/13-small.d796bffd.png",
                              "userRole": r2.user.role,
                              "inviteCode": r2.user.invite_code,
                              "ability": currentAbility.ability
                            }
                window.localStorage.setItem('userData', JSON.stringify(uData));
                this.$ability.update(currentAbility.ability)

                if (r2.user.role == 'moderator')
                  window.location.href = '/chat-moderator-page'
                else if (r2.user.role == 'master')
                  window.location.href = '/master-users'
                else
                  window.location.href = '/'
              } else {
                // eslint-disable-next-line no-alert
                this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'EditIcon',
              variant: 'error',
            },
          })
          
              }
            })
          }).catch(e => {
                            this.$toast({
            component: ToastificationContent,
            props: {
              title: e,
              icon: 'EditIcon',
              variant: 'error',
            },
          })
          })
          /*
          window.localStorage.setItem('username', this.username)
          window.localStorage.setItem('userId', this.userId)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Form Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          })

          window.location.href = '/chat-moderator-page' */
        }
      })
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
